import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import './Image.css'

class Image extends React.Component {
  render() {
    let { background, onClick, alt = '', featuredImgGatsby } = this.props

    return (
      <Fragment>
        {featuredImgGatsby && (
          <Fragment>
            {background && (
              <BackgroundImage
                Tag={`div`}
                className={'BackgroundImage'}
                fluid={featuredImgGatsby.childImageSharp.fluid}
                style={{ position: 'absolute' }}
              />
            )}
            {!background && (
              <Img
                fluid={featuredImgGatsby.childImageSharp.fluid}
                alt={alt}
                title={alt}
                onClick={onClick}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

Image.propTypes = {
  alt: PropTypes.string.isRequired
}

export default Image
